<template>
  <div>
    <button class="close" @click="goto(1)">X</button>
    <Video
      url="https://player.vimeo.com/progressive_redirect/playback/866767001/rendition/1080p/file.mp4?loc=external&signature=bc26202b58464e635556a94d4dfbf61cb31bd90d51fe9d8af6001a9961df3f08"
    />
  </div>
</template>

<script>
import Video from "@/components/Video.vue";

export default {
  name: "Videoplayer",
  components: {
    Video,
  },

  methods: {
    goto: function (page) {
      var pageURL = "/page/" + page;
      this.$router.push({ path: pageURL });
    },
  },
};
</script>

<style lang="scss">
.close {
  position: absolute;
  z-index: 9999;
  top: 2vh;
  right: 2vw;
  cursor:pointer;
}
</style>
